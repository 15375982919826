import toast from "react-hot-toast";
import authAPI from "../../axios-setup/auth-service";
import { Subject } from "rxjs";
import setAuthToken from "../../utils/setAuthToken";

const successResponse = (data=undefined) => {
    return {'success': true, 'data': data };
}

const errorResponse = () => {
    return {'success': false};
}

const authSubject = new Subject();

export const authObservable = {
    showAuth: () => authSubject.next({
      show: true,
    }),
    hideAuth: () => authSubject.next({
      show: false,
    }),
    userLoggedIn: () => authSubject.next({
      loggedin: true
    }),
    getAuth: () => authSubject.asObservable()
};


export const withAuth = (callback) => async (e) => {
  if(e){
    e.stopPropagation();
  }
  await checkAuth(() => callback(e));
};

const checkAuth = async (callback) => {
  if (!AuthService.isLoggedIn()) {
    authObservable.showAuth();
    console.log("Not Logged in");
    return;
  }
  await callback();
};

export const AuthService = {
    isLoggedIn: () => {
        return  !!localStorage.getItem("token");
    },
    sendOTP: async(payload) => {
        try {
            const res = await authAPI.post("/api/v1/auth/sendotp", payload);
        
            if (res.data.success) {
              return successResponse();
            } else {
              return errorResponse();
            }
          } catch (err) {
            return errorResponse();
          }
    },

    verifyOTP: async(payload) => {
        try {
            const res = await authAPI.post("/api/v1/auth/verifyotp", payload);
        
            if (res.data.success) {
                const token = res.data.data.authToken;
                await setAuthToken(token);

              return successResponse(res.data.data);
            } else {
              return errorResponse();
            }
          } catch (err) {
            return errorResponse();
          }
    },
    logout: () => {
      delete authAPI.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
    }

};